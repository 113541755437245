/**
 * @file Allow using app feature flags
 */
import { hasWwDebugUrlParam } from '@@/bits/dev_url_params'
import environment from '@@/bits/environment'
import window from '@@/bits/global'

type CurrentFlags =
  | 'banksy'
  | 'banksyDev'
  | 'dashboardAnnouncement'
  | 'debugFrontend'
  | 'joypixels8p0'
  | 'goldPlansVisible'
  | 'aiImageSearch'
  | 'googleDrive'
  | 'realtimeFetching'
  | 'brahmsCheck'
  | 'reportButton'
  | 'magicPadlet'
  | 'surfaceAiChat'
  | 'spreadsheetExportsV2'
  | 'remakeSettingV2'
  | 'showCommandPalette'
  | 'publicApi'
  | 'autoContentModeration'
  | 'changeEmailVerification'
  | 'surfaceCommentsPerPost'
  | 'onboardingPanel'
  | 'galleryTemplates'
  | 'layoutFormatSettingV3'
  | 'templatesWithNewSidebar'
  | 'classlinkSso'
  | 'surfaceLibraryOrgVisitorPermissions'
  | 'surfacePdfExportPanelV2'
  | 'editThumbnail'
  | 'virtualizedDashboard'
  | 'commentModeration'
  | 'googleAppLicensing'
  | 'rostering'
  | 'cleverSso'
  | 'stripeCheckout'
  | 'fullClientSurface'
  | 'delinquentChurnBanner'
  | 'personalPricingPlansExperiment'
  | 'personalPricingPlansExperimentV2'
  | 'suggestedTemplates'
  | 'dashboardUpgradeCta'
  | 'surfaceRoleBadges'
  | 'keyboardShortcutsSetting'
  | 'autoDismissAppMessagesSetting'
  | 'remakeLink'
  | 'sandboxVisualFx'
  | 'sandboxSoundFx'
  | 'virtualizedPostsGrid'
  | 'lazyRenderPosts'
  | 'dashMakeButton'
  | 'dashboardFolderDalfe'
  | 'remakePanelV2'
  | 'signUpSurface'
  | 'playVideoInExpanded'
  | 'grading'
  | 'schoolLibrarySSO'
  | 'surfaceWhiteboard'
  | 'freezeSandbox'
  | 'samlSetup'
  | 'removeArchive'
  | 'aiTalk'
  | 'locationSelectorV2'
  | 'openInAppBanner'
  | 'demoPadletPanel'
  | 'demoPadletSignup'
  | 'onboardingDemoV2'
  | 'onboardingDemoV2Copy'
  | 'upgradeModalCopyAExperiment'
  | 'showAnonymousUserNames'
  | 'mediaRecorderQuotas'
  | 'padletPickerV2'
  | 'downloadWallAttachmentsV2'
  | 'commentAttachmentSettings'
  | 'aiGrading'
  | 'upgradeModalSingleStepExperiment'
  | 'locationPickerV2'
  | 'schoolLibraryPermissions'
  | 'lmsUserGroupSync'
  | 'libraryGoogleAppLicensing'
  | 'linkSafetyModal'
  | 'newSignupWelcomePage'
  | 'libraryMembersPageV2'
  | 'libraryAttachmentSettings'
  | 'prefetchSurface'
  | 'unlimitedSandboxes'
  | 'currentSlideAsImageWithBanksy'
  | 'deprecateDrivePage'
  | 'wallContentProtection'
  | 'higherMonthlyPricingPlans'
  | 'padletAnalytics'
  | 'arLensesPreview'
  | 'reorderCustomFieldsWithKeyboard'
  | 'lottieWebWorker'
  | 'sandboxCode'
  | 'classInformation'
  | 'fetchLinksWithWishes'
  | 'transferSchoolPadletPermission'
  | 'exitTicket'
  | 'featuredSection'
  | 'videoTrimming'
  | 'tenantFreeze'
  | 'expandCommentImage'
  | 'sandboxRetainActiveTool'
  | 'sandboxTextSizeModifier'
  | 'sandboxPlayModeAutoplay'
  | 'wallEngagementTime'
  | 'wallEngagementTimeTracking'
  | 'highContrastMode'
  | 'translationReporter'
  | 'reducedMotionSetting'
  | 'sandboxPngFramePreview'
  | 'librarySamlLogin'
  | 'translatePadlet'
  | 'pageSpecificA11ySettings'
  | 'customPostEmailProperty'
  | 'customPostPhoneNumberProperty'
  | 'customPostUrlProperty'
  | 'customPostUserProperty'
  | 'sandboxCardNavigation'
  | 'sandboxCardTemplates'
  | 'tableLayout'
  | 'captionsUploadAndDownload'
  | 'screenRecorderV2'
  | 'transferPadletTool'
  | 'commandBar'
  | 'yodishLanguage'
  | 'columnsLayout'
  | 'surfaceMapsV2'
  | 'sandboxTextFonts'
  | 'cacheLoginOption'
  | 'classroomLicensesV2'
  | 'audioPlayerV2'
  | 'audioPlayerV2Sandbox'
  | 'pdAndWebinarButtons'
  | 'sandboxSvgIcons'
  | 'addPostLink'
  | 'editThumbnailForAudioVideoFiles'
  | 'allSlideAsImageWithBanksy'
  | 'photoAlbum'
  | 'sandboxThemeBackgrounds'
  | 'defaultDashboardLibraryNavigation'
  | 'classroomPlanPricingExperiment'
  | 'schoolLibraryOnboardingV2'
  | 'aiPermissions'

function isAppUsing(feature: CurrentFlags, version?: null | number): boolean {
  const ww = window.ww || {}
  const viteAppFlip =
    window.wwStartingState != null && typeof window.wwStartingState === 'function' ? window.wwStartingState().flip : {}
  const flip = ww.flip || ww.vueStartingState?.flip || viteAppFlip || {}
  if (version) {
    return flip[feature] >= version
  }
  return flip[feature] || false
}

export const isDebugMode =
  (environment === 'test' && ['true', 'yes', '1'].includes(process.env.WW_DEBUG ?? '')) ||
  isAppUsing('debugFrontend') ||
  hasWwDebugUrlParam()

if (environment !== 'production' && isAppUsing('debugFrontend')) {
  console.log('Already debugging frontend in dev.') // goodcheck-disable-line
}

// If on padlet.dev, use the local Banksy server to test PDF exports.
// Banksy and Banksy Dev on Cloud Run won't work because they cannot access a padlet.dev padlet.
// You will need to run the Banksy server first before you can test PDF exports.
// See: /services/banksy/README.md
export const isAppUsingBanksyLocalDev = environment === 'development'

export { isAppUsing }
